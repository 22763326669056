.social-media-wrapper {
  display: inline-block;
  margin: 25px 0;
  ul {
    padding: 5px 0 0 0;

    @media screen and (min-width: 768px) {
      padding: 25px 0;
    }
  }
  a {
    color: white;
  }
  .social-media {
    li {
      display: inline-block;
      padding: 0 5px 0 5px;
    }
    svg {
      width: 38px;
      height: 38px;

      @media screen and (min-width: 768px) {
        width: 40px;
        height: 40px;
      }
    }
  }
}