.about {
  h1 {
    color: white;
  }

  padding: 35px 0;
  background: rgba(0, 0, 0, 0.65);

  .image-about-wrapper {
    margin: 0 0 15px 0;

    @media screen and (min-width: 992px) {
      margin: auto;
    }
  }

  .list-about-item-text {
    margin: auto 0;
  }

  .list-about-item-image {
    margin: 0 0 15px 0;

    @media screen and (min-width: 992px) {
      margin: auto 0;
    }
  }

  .about-text-block {
    color: white;
    font-size: 14px;

    @media screen and (min-width: 992px) {
      font-size: 15px;
    }
  }

  .list-about-title {
    margin: 0 0 5px 15px;
  }

  .list-item {
    margin: 15px 0;
  }
}