.navbar {
  a {
    color: white !important;
  }
  background: linear-gradient(to bottom, black, transparent);
}


.bg-light {
  background-color: $color-header-footer !important;

  .navbar-toggler {
    outline: none;
  }

  .nav-icon {
    width: 40px;
  }

  .nav-icon:after,
  .nav-icon:before,
  .nav-icon div {
    background-color: white;
    border-radius: 3px;
    content: '';
    display: block;
    height: 5px;
    margin: 7px 0;
    transition: all .2s ease-in-out;
  }

  .open-burger {
    .navbar-toggler-icon {
      background-color: #9fcdff;
    }
  }

  .close-burger {
    .nav-icon div {
      transform: scale(0);
    }

    .nav-icon:before {
      transform: translateY(12px) rotate(135deg);
    }

    .nav-icon:after {
      transform: translateY(-12px) rotate(-135deg);
    }
  }

}

.navbar-light .navbar-toggler {
  color: white;
  border-color: white;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: white;
}

.navbar-nav {
  .active {
      color: #17a2b8 !important;
  }
}
