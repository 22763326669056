.games-list {

  .games-list-title {
    background: rgba(0, 0, 0, 0.65);
    padding: 5px 0;
    text-align: center;
  }

  .list-item-wrapper:not(:first-child) {
    margin: 25px 0 0 0;
  }

  @media screen and (min-width: 768px) {
    .list-item-wrapper:nth-child(n+1) {
      margin: 0;
    }
  }
  .card-hover {
    transition: transform .5s;
    &:hover,
    &:focus {
      transform: scale3d(1.026, 1.026, 1);
    }
  }

  h1 {
    color: white;
  }
  .list-item {
    height: 100%;
    width: 100%;
  }

  .card {
    cursor: pointer;
    height: 100%;
  }

  a {
    color: black;
    &:hover {
      color: black;
      text-decoration: none;
    }
  }
}
