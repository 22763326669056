@import "additional/socialmedia";
@import "additional/publisher";
.footer {
  text-align: center;
  background: linear-gradient(to top, black, transparent);
  padding: 20px 0;

  h3 {
    color: white;
    font-size: 22px;

    @media screen and (min-width: 768px) {
      font-size: 26px;
    }
  }
}