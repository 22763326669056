.publisher-wrapper {
  margin: 0;

  @media screen and (min-width: 768px) {
    margin: 25px 0;
  }

  .logo {
    margin: 8px 0;
  }
}