.cookie-banner {
  position: sticky;
  bottom:0;
  width:100%;
  transition: all 0.25s ease-in-out;
  background: rgba(0, 0, 0, 100);
  z-index: 999;
  padding: 15px 0;

  .cookie-banner-text {
    color: white;
    margin: 0 0 15px 0;

    @media screen and (min-width: 768px) {
      margin: auto 0;
    }

  }

  span {

  }
}